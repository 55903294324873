<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card class="mt-6">
        <div class="d-flex grow flex-wrap pa-3">
          <div
            class="text-start v-card--material__heading mb-n6 v-sheet theme--dark primary pa-2"
            style="max-height: 90px; width: auto;"
          >
            <v-icon
              dark
              large
            >
              mdi-account
            </v-icon>
          </div>
          <div class="ml-4">
            <div class="card-title font-weight-light">
              {{ $t('messages.user') }}
            </div>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="users.data"
          :items-per-page="5"
          class="elevation-1"
        >
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template #[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <WorldMap />
    </v-col>
  </v-row>
</template>

<script>
import WorldMap from '@/components/widget/WorldMap'
import authHeader from '@/utils'

export default {
  name: 'Home',
  components: { WorldMap },
  data () {
    return {
      headers: [
        { text: '#', value: 'id' },
        { text: this.$t('messages.name'), value: 'name', sortable: false },
        { text: this.$t('messages.email'), value: 'email', sortable: false },
        { text: this.$t('messages.created_at'), value: 'created_at', sortable: false },
        { text: this.$t('messages.updated_at'), value: 'created_at', sortable: false }
      ],
      users: {
        data: []
      }
    }
  },
  mounted () {
    this.$http
      .get('/users', { headers: authHeader() })
      .catch(error => this.displayError(error))
      .then((response) => {
        // console.log(response)
        this.users = response.data
      })
  }
}
</script>

<style>
  .v-card .v-card--material__heading {
    top: -30px;
  }
  .v-card--material__heading {
    position: relative;
    top: -70px;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    z-index: 1;
  }
  .vue-world-map {
    max-height: 350px;
  }
  .vue-world-map .land{stroke:#fff!important}
  .vue-world-map .land {
    fill: #dadada;
    stroke: black;
  }
  .vue-world-map #US {
    fill: #838383;
  }
  .vue-world-map #BR {
    fill: #bbbbbb;
  }
  .vue-world-map #AU {
    fill: #b6b6b6;
  }
  #map-svg, .vue-world-map {
    height: 100%;
  }
</style>
